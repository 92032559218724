<template>
  <div id="dyeingAndFinishingOrder" class="dyeingAndFinishingOrder">
    <!-- 顶部筛选 -->
    <div class="filter">
      <div class="left">
        <div class="filterItem">
          <div style="text-align: right;margin-right:10px">合同号</div>
          <Input placeholder="请输入搜索内容" v-model="contractNo" @keyup.enter.native="handleChangeFilter()" style="width: 150px" />
        </div>
        <div class="filterItem">
          <div style="text-align: right;margin-right:10px">染整单号</div>
          <Input placeholder="请输入搜索内容" v-model="dyeingOrderNumber" @keyup.enter.native="handleChangeFilter()" style="width: 150px" />
        </div>
        <div class="filterItem">
          <div style="text-align: right;margin-right:10px">坯布货号</div>
          <Input placeholder="请输入搜索内容" v-model="productArticleNumber" @keyup.enter.native="handleChangeFilter()" style="width: 150px" />
        </div>
                <div class="filterItem">
          <div style="text-align: right;margin-right:10px">业务担当</div>
          <Input placeholder="请输入搜索内容" v-model="ztdpoTakeOn" @keyup.enter.native="handleChangeFilter()" style="width: 150px" />
        </div>
        <div class="filterItem">
          <div style="text-align: right;margin-right:10px">制单日期</div>
          <DatePicker
            v-model="formDate"
            type="daterange"
            placeholder="请选择"
            style="width: 220px"
            @keyup.enter.native="handleChangeFilter()"
          ></DatePicker>
        </div>
        <div class="filterItem">
        <div style="margin-right:10px;min-width:26px">状态</div>
        <Select
          slot="list"
          style="width: 150px"
          v-model="deliveryStatus"   
           @on-change="tableDataList"   
        >
          <Option value="-1"
            >全部</Option
          >
          <Option value="2"
            >正常</Option
          >
          <Option value="1"
            >延期</Option
          >
        </Select> 
      </div>
        <div class="filterItem">
        <!-- <Button @click="handleClickfilter">
          自定义筛选
           <i v-if="showUserDefined" class="iconfont iconicon-xia"></i>
          <i v-if="!showUserDefined" class="iconfont iconicon-shang"></i>
        </Button> -->
      </div>
      </div>
    </div>
    <!--自定义筛选显示-->
    <!-- <div class="userDefined" v-if="showUserDefined">
      <div class="left">
        <div class="select_container" v-if="showSelect" style="margin-bottom:10px;">
          <Checkbox
            :indeterminate="indeterminate1"
            :value="checkAll1"
            @click.prevent.native="handleCheckAll1"
          >全选</Checkbox>
          <div class="divide"></div>
          <CheckboxGroup v-model="checkAllGroup1" @on-change="checkAllGroupChange1">
            <Checkbox :label="item.name" v-for="item in boxList1" :key="item.name"></Checkbox>
          </CheckboxGroup>
        </div>

        <div class="filter_container" v-else>
          <div class="filterItem" v-if="checkAllGroupShow1.includes('坯布规格')">
            <div style="text-align: right;margin-right:10px">坯布规格</div>
            <Input placeholder="请输入搜索内容" @keyup.enter.native="handleChangeFilter()" style="width: 150px" />
          </div>
          <div class="filterItem" v-if="checkAllGroupShow1.includes('担当')">
            <div style="text-align: right;margin-right:10px">担当</div>
            <Input placeholder="请输入搜索内容" @keyup.enter.native="handleChangeFilter()" style="width: 150px" />
          </div>
          <div class="filterItem" v-if="checkAllGroupShow1.includes('订单交期')">
            <div style="text-align: right;margin-right:10px">订单交期</div>
            <Input placeholder="请输入搜索内容" @keyup.enter.native="handleChangeFilter()" style="width: 150px" />
          </div>
        </div>
      </div>
      <div class="right"> -->
        <!-- <Button class="cancel" v-if="showSelect" @click="handleClickCancel">取消</Button> -->
        <!-- <Button class="confirm" v-if="showSelect" @click="handleClickConfirm">确定</Button>
      </div>
    </div> -->
    <!-- 状态单选 -->
    <!-- <div class="box">
      <div class="boxItem">
        <span style="margin-right: 10px">状态：</span>
        <Checkbox
          :indeterminate="indeterminate2"
          :value="checkAll2"
          @click.prevent.native="handleCheckAll2"
        >全选</Checkbox>
        <CheckboxGroup v-model="checkAllGroup2" @on-change="checkAllGroupChange2">
          <Checkbox :label="item.name" v-for="item in boxList2" :key="item.name"></Checkbox>
        </CheckboxGroup>
      </div>

    </div> -->

    <!-- 按钮 -->
    <div class="button">
      <div class="buttonRow">
        <div class="left">
          <div class="item" @click="handleChangeFilter()">
            <i class="iconfont iconicon-shuaxin" />
            刷新
          </div>
          <div class="item" @click="handleClickSync">
            <i class="iconfont iconicon-tongbu" />
            同步
          </div>
          <div class="item" @click="handleClickExport()">
            <i class="iconfont iconicon-daochu" />
            导出
          </div>
          <div class="divide"></div>
          <div class="item" @click="handleClickTableSetting">
            <i class="iconfont iconicon-chuansuokuang" />
            表格设置
          </div>
        </div>
        <div class="right">
        </div>
      </div>
      <!-- 可以扩展  -->
    </div>

    <!-- 表格 -->
    <Table :columns="tableColumns" :loading="loading" show-summary :summary-method="handleSummary" :data="tableData" border></Table>

    <!-- 分页 -->
    <Page
      class-name="page"
      :total="listData.total"
      show-elevator
      show-sizer
      show-total
      :page-size="listData.pageSize"
      :current="listData.pageNum"
      @on-page-size-change="pageSizeChange"
      @on-change="pageNumberChange"
    />
    <!-- 表格设置 -->
    <TableSetting
      :chuansuoDialogVisible.sync="chuansuoDialogVisible"
      :routerPath="routerPath"
      :theadDateReal="theadDateReal"
      @tableDataList="tableDataList"
    />
  </div>
</template>

<script>
import TableSetting from "../../components/home/tableSetting.vue";
export default {
  components: {
    TableSetting
  },
  data() {
    return {
      statusList: ['全部', '正常', '延期'],
      contractNo: "", // 合同号
      ztdpoTakeOn: "", // 业务担当
      dyeingOrderNumber: "", // 染整单号
      productArticleNumber: "", // 成品货号
      formDate: "", // 制单日期
      deliveryStatus: -1,
      loading: false, // 是否在加载中
      routerPath: "/dtsum/zongtong/dyeing/WebDyeingController/dyeingPlanOrderSheetSearch",
      theadDateReal: [],
      chuansuoDialogVisible: false, // 表格设置显示

      showUserDefined: false, // 是否展示自定义筛选模块
      showSelect: false, // true展示多选框，false展示筛选条件

      indeterminate1: false, // 只负责样式控制,true为是
      checkAll1: false, // 是否全选
      checkAllGroup1: [], // 已选的多选框的存储
      checkAllGroupShow1: [], // 筛选条件已选的存储

      indeterminate2: false, // 只负责样式控制,true为是
      checkAll2: false, // 是否全选
      checkAllGroup2: [], // 已选的多选框的存储
      tableColumnsReady: [
        {
          title: "状态",
          key: "timeStatus",
          minWidth: 100,
          align: "left"
        },
        {
          title: "合同号",
          key: "ztdpoContractNo",
          minWidth: 140,
          align: "left"
        },
        {
          title: "坯布货号",
          key: "ztdpoArticleNumber",
          minWidth: 130,
          align: "left"
        },
        {
          title: "染整单号",
          key: "ztdpoDyeingOrderNumber",
          minWidth: 180,
          align: "left"
        },
        {
          title: "染整状态",
          key: "ztdfoStatus",
          minWidth: 150,
          align: "left"
        },
        {
          title: "跟单员",
          key: "followerName",
          minWidth: 100,
          align: "left"
        },
        {
          title: "品名",
          key: "ztdpoProductName",
          minWidth: 100,
          align: "left"
        },
        {
          title: "门幅",
          key: "ztdpoLarghezza",
          minWidth: 120,
          align: "left"
        },
        {
          title: "克重",
          key: "ztdpoGram",
          minWidth: 100,
          align: "left"
        },
        {
          title: "制单日期",
          key: "ztdpoFormDate",
          minWidth: 150,
          align: "left"
        },
        {
          title: "条距",
          key: "ztdpoBarSpacing",
          minWidth: 150,
          align: "left"
        },
        {
          title: "需求数",
          key: "ztdpoDemandNumber",
          minWidth: 100,
          align: "left"
        },
        {
          title: "坯布规格",
          key: "ztdpoGreyClothSpecification",
          minWidth: 300,
          align: "left"
        },
        {
          title: "辅助光源",
          key: "ztdpoAuxiliaryLightSource",
          minWidth: 100,
          align: "left",
        },
        {
          title: "业务担当",
          key: "ztdpoPlanningResponsibility",
          minWidth: 100,
          align: "left"
        },
        {
          title: "订单交期",
          key: "ztdpoOrderDeliveryDate",
          minWidth: 120,
          align: "left"
        },
        {
          title: "成品规格",
          key: "ztdpoproductSpecifications",
          minWidth: 200,
          align: "left"
        },
        {
          title: "平整规格",
          key: "ztdpoFlatSpecifications",
          minWidth: 200,
          align: "left"
        },
        {
          title: "撕破强力",
          key: "ztdpoTearStrength",
          minWidth: 150,
          align: "left"
        },
        {
          title: "合理损耗及计算方法",
          key: "ztdpoReasonableLoss",
          minWidth: 200,
          align: "left"
        }
      ],
      tableColumns: [],
      tableData: [], // 主表的数据
      listData: {
        // 主表的分页数据
        pageSize: 10,
        pageNum: 1,
        total: 0
      },
      userFilds: [] // 主表的显示的表格头
    };
  },
  mounted(){
    this.tableDataList()
  },
  activated(){
    this.tableDataList()
  },
  methods: {
    // 合计显示的内容
    handleSummary({ columns, data }){
      const sums = {};
      columns.forEach((column, index) => {
        const key = column.key;
        if (index === 0) {
          sums[key] = {
            key,
            value: "累计"
          };
          return;
        }
        data.map((item) => Number(item[key]));
        if (key === "ztdpoDemandNumber") {
          sums[key] = {
            key,
            value: this.demandNumberTotal
          };
        } else {
          sums[key] = {
            key,
            value: ""
          };
        }
      });

      return sums;
    },
    // 修改筛选条件
    handleChangeFilter(params){
      this.tableDataList()
    },
    // 同步
    handleClickSync(){
      this.axios({
        url: "/dtsum/zongtong/basic/CommonController/synTable",
        method: 'get',
      }).then((res) => {
        if (res.data.success === 1){
          this.$Message.success('同步成功！')
        } else {
          this.$Message.warning(res.data.msg)
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    // 导出
    handleClickExport() {
      window.location.href = `${this.baseUrl}/dtsum/zongtong/dyeing/WebDyeingController/dyeingPlanOrderSheetExport?contractNo=${this.contractNo}&dyeingOrderNumber=${this.dyeingOrderNumber}&productArticleNumber=${this.productArticleNumber}&formDateStart=${this.formDate[0] ? this.moment(this.formDate[0]).format('YYYY-MM-DD HH:mm:ss') : ''}&formDateEnd=${this.formDate[1] ? this.moment(this.formDate[1]).format('YYYY-MM-DD HH:mm:ss') : ''}`;
    },
    // 获取表格数据
    tableDataList() {
      this.loading = true;
      this.axios({
        url: "/dtsum/zongtong/dyeing/WebDyeingController/dyeingPlanOrderSheetSearch",
        method: "get",
        params: {
          pageSize: this.listData.pageSize, // 页大小
          pageNum: this.listData.pageNum, // 页号
          params: {
            contractNo: this.contractNo, // 合同号
            dyeingOrderNumber: this.dyeingOrderNumber, // 染整单号
            productArticleNumber: this.productArticleNumber, // 成品货号
            formDateStart: this.formDate[0] ? this.moment(this.formDate[0]).format('YYYY-MM-DD HH:mm:ss') : '', // 制单日期开始
            formDateEnd: this.formDate[1] ? this.moment(this.formDate[1]).format('YYYY-MM-DD HH:mm:ss') : '', // 制单日期结束
            deliveryStatus: this.deliveryStatus, // 1延期，2正常
            ztdpoTakeOn: this.ztdpoTakeOn // 业务担当
          }
        }
      }).then(res => {
        this.loading = false
        if (res.data.success === 1) {
          this.demandNumberTotal = res.data.body.demandNumberTotal;
          const dyeingPlanOrderList = res.data.body.dyeingPlanOrderList;
          this.tableData = JSON.parse(JSON.stringify(dyeingPlanOrderList.list));
          const nowTime = this.moment(new Date()).valueOf()
          console.log(this.tableData)
          this.tableData.forEach((item) => {
            if (this.moment(item.ztdpoOrderDeliveryDate).valueOf() < nowTime){
              item.timeStatus = '延期'
            } else {
              item.timeStatus = '正常'
            }
          })
          console.log(this.tableData)
          this.listData.pageSize = dyeingPlanOrderList.pageSize;
          this.listData.pageNum = dyeingPlanOrderList.pageNum;
          this.listData.total = dyeingPlanOrderList.total;
          this.userFilds = res.data.body.userFilds;
          this.tableColumns = [
            {
              title: "序号",
              type: "index",
              width: 80,
              align: "center",
              render: (h, params) => {
            return h(
              "span",
              params.index +
                (this.listData.pageNum - 1) * this.listData.pageSize +
                1
            );
          },
            },
            {
              title: "操作",
              key: "action",
              width: 100,
              align: "center",
              render: (h, params) => {
                return h("div", [
                  h(
                    "span",
                    {
                      style: {
                        fontSize: "14px",
                        color: "rgb(87, 163, 243)",
                        cursor: 'pointer'
                      },
                      on: {
                        click: () => {
                          this.$router.push({ path: '/dyeingAndFinishing/dyeingAndFinishingOrderDetail', query: { dyeingOrderNumber: params.row.ztdpoDyeingOrderNumber, contract: params.row.ztdpoContractNo, followerName: params.row.followerName, ztdpoStatus: params.row.ztdpoStatus } })
                        }
                      }
                    },
                    "详情"
                  )
                ]);
              }
            },
          ]
          this.userFilds.map((item) => {
            this.tableColumnsReady.map((itemIn) => {
              if (itemIn.key === item.field){
                this.tableColumns.push(itemIn)
              }
            })
          })
        }
      }).catch(err => {
        console.log(err)
      })
    },
    // 点击表格设置
    handleClickTableSetting() {
      this.chuansuoDialogVisible = true;
    },
    // 自定义筛选
    handleClickfilter() {
      this.showSelect = !this.showSelect;
      if (this.showSelect === true) {
        // 从筛选条件跳转到多选框
        this.showUserDefined = true;
        this.checkAllGroup1 = this.checkAllGroupShow1;
      } else {
        // 从多选框跳到筛选条件
        if (this.checkAllGroupShow1.length === 0) {
          this.showUserDefined = false;
        }
      }
    },
    // 确认多选框跳转到筛选条件
    handleClickConfirm() {
      this.showSelect = false;
      this.checkAllGroupShow1 = this.checkAllGroup1;
      if (this.checkAllGroupShow1.length === 0) {
        this.showUserDefined = false;
      }
    },
    // 跳转到筛选条件的多选框
    handleClickSelectButton() {
      this.showSelect = true;
    },
    pageSizeChange(pageSize) {
      this.listData.pageSize = pageSize;
      this.listData.pageNum = 1;
      this.tableDataList()
    },
    pageNumberChange(pageNum) {
      this.listData.pageNum = pageNum;
      this.tableDataList()
    },
    handleCheckAll1() {
      // 全选
      if (this.indeterminate1) {
        this.checkAll1 = false;
      } else {
        this.checkAll1 = !this.checkAll1;
      }
      this.indeterminate1 = false;

      if (this.checkAll1) {
        this.checkAllGroup1 = [
          "工厂名称",
          "种类",
          "面料颜色",
          "客户款号",
          "计划日期",
          "担当",
          "状态"
        ]; // 这里改为全部的状态
      } else {
        this.checkAllGroup1 = [];
      }
      console.log(this.checkAllGroup1);
    },
    checkAllGroupChange1(data) {
      if (data.length === this.boxList1.length) {
        this.indeterminate1 = false;
        this.checkAll1 = true;
      } else if (data.length > 0) {
        this.indeterminate1 = true;
        this.checkAll1 = false;
      } else {
        this.indeterminate1 = false;
        this.checkAll1 = false;
      }
    },
    handleCheckAll2() {
      // 全选
      if (this.indeterminate2) {
        this.checkAll2 = false;
      } else {
        this.checkAll2 = !this.checkAll2;
      }
      this.indeterminate2 = false;

      if (this.checkAll2) {
        this.checkAllGroup2 = ["待穿纱", "待改机", "量产中"]; // 这里改为全部的状态
      } else {
        this.checkAllGroup2 = [];
      }
    },

  }
};
</script>

<style lang="scss" scoped>
#dyeingAndFinishingOrder {
  font-size: $--fontsize-title;
  .filter {
    font-size: $--fontsize-small;
    display: flex;
    position: relative;
    .left {
      width:100%;
      .filterItem {
        display: inline-flex;
        margin: 0 50px 10px 0;
        div {
          line-height: 32px;
        }
        &:last-of-type{
          float: right;
        }
      }
    }
  }
  .userDefined {
    font-size: $--fontsize-small;
    background: #F4F7FE;
    padding: 10px 0 0 0;
    border-radius: $--radius-standard;
    position: relative;
    display: flex;
    .divide{
      display: inline-block;
      width:1px;
      height:10px;
      margin-top:2px;
      margin-right:10px;
      background-color: #dcdada;
    }
    .left {
      width: calc(100% - 150px);
      .filterItem {
        display: inline-flex;
        margin: 0 50px 10px 0;
        div {
          line-height: 32px;
        }
      }
    }
    .right {
      width: 150px;
      position: relative;
    }
  }
  .box {
    font-size: $--fontsize-small;
    .boxItem {
      margin: 10px 0;
    }
  }
  .button {
    font-size: $--fontsize-small;
    .divide{
      width:1px;
      height:10px;
      display: inline-block;
      margin-top:2px;
      margin-right:20px;
      background-color: #dcdada;
    }
    .buttonRow {
      display: flex;
      justify-content: space-between;
      margin: 10px 0;
      color:#256de6;
      .item {
        display: inline-block;
        cursor: pointer;
      }
      .left {
        .item {
          margin-right: 30px;
          &:nth-of-type(2){
            margin-right:20px;
          }
        }
      }
      .right {
        .item {
          margin-left: 10px;
        }
        .filterButton{
          width:120px;
          margin-right:30px;
          height:32px;
          line-height:32px;
          text-align: center;
        }
      }
    }
  }
  .page {
    font-size: $--fontsize-small;
    text-align: center;
    margin-top: 10px;
  }
}
</style>
<style lang="scss">
#dyeingAndFinishingOrder {
  .filter {
    .right {
      .ivu-btn {
        position: absolute;
        bottom: 10px;
        color: $--color-blue-standard;
      }
    }
  }
  .userDefined {
    .right {
      .cancel{
        position: absolute;
        left:0;
        bottom:5px;
      }
      .confirm {
        position: absolute;
        right: 10px;
        bottom: 5px;
      }
        .ivu-btn-default{
          color:#FFF;
          background: #256DE6;
        }
      .select_button {
        color: $--color-blue-standard;
        display: flex;
        position: absolute;
        top: calc(50% - 5px);
        transform: translateY(-50%);
        &::before {
          content: "";
          display: block;
          width: 2px;
          height: 20px;
          margin-right: 10px;
          background-color: #dcdada;
        }
      }
    }
  }
  .ivu-select-selection {
    height: 32px !important;
  }
  .ivu-select-placeholder {
    line-height: 32px !important;
    height: 32px !important;
  }
  .ivu-select-selected-value {
    line-height: 32px !important;
    height: 32px !important;
  }
  .ivu-input {
    height: 32px !important;
  }
  .ivu-input-suffix i {
    line-height: 32px !important;
  }
  .ivu-icon-ios-time-outline {
    line-height: 32px !important;
  }
  .ivu-checkbox-default {
    display: inline-block;
  }
}
</style>
